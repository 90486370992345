<ng-template #licenseRequired>
  @if (displayService.showLicenseRequired()) {
    <span>
      <a href="#" class="madero-link" (click)="confirmLicensing()" [shown]="userStateService.hasRole('da')">License Required</a>
      <span [shown]="!userStateService.hasRole('da')">License Required</span>
    </span>
  }
</ng-template>
<div class="fields-container">
  <div class="row">
    <div class="col-sm-12 col-md-7 pb-2 pb-lg-0">
      <div class="flex-row">
        <stretchy-input name="displayNameInput" ngDefaultControl class="mb-0" [(ngModel)]="displayService.display.name"></stretchy-input>
        <button type="button" id="deleteButton" aria-label="Delete display" *requireRole="'da'" [disabled]="!displayService.display.id" (click)="confirmDelete()" class="btn-icon pl-0">
          <streamline-icon ariaLabel="Delete display" class="u_margin-left streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
        </button>
      </div>
    </div>
    <div class="col-sm-12 col-md-5 pl-lg-0">
      <div class="pull-right" [hidden]="!displayService.display || !displayService.display.id">
        <label class="mr-2">Media Player Actions:</label>
        <div class="btn-group dropdown" dropdown>
          <button id="displayActionsButton" type="button" [matMenuTriggerFor]="displayActionsMenu" class="form-control btn-select btn-schedule-selector btn-toolbar flex-row">
            <div class="row-entry">
              <span class="text-gray mr-auto">Choose an action</span>
              <span class="text-gray pl-2"><i aria-hidden="true" class="fa fa-sort"></i></span>
            </div>
          </button>
          <mat-menu #displayActionsMenu="matMenu">
            <button mat-menu-item type="button" [hidden]="displayService.display.onlineStatus === 'not_activated' || !playerProService.isRestartCompatiblePlayer(displayService.display)" [disabled]="!displayService.display.playerVersion" (click)="playerActionsService.confirm(displayService.display.id, displayService.display.name, 'restart')" *requireRole="'da'">
              Restart Rise Player
            </button>
            <button mat-menu-item type="button" [hidden]="displayService.display.onlineStatus === 'not_activated' || !playerProService.isRebootCompatiblePlayer(displayService.display)" [disabled]="!displayService.display.playerVersion" (click)="playerActionsService.confirm(displayService.display.id, displayService.display.name, 'reboot')" *requireRole="'da'">
              Reboot Media Player
            </button>
            <button mat-menu-item id="installPlayerAction" uiSref="apps.displays.install">
              <span>Install Display</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- DISPLAY ERRORS AND ALERTS -->
<div [shown]="playerActionsService.controlsError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{playerActionsService.controlsError}}</strong>
</div>
<div class="fields-preview-panels display-fields-body mt-4" common-header-height>
  <mat-tab-group #TabGroup [selectedIndex]="selectedTabIndex" class="display-tabs">
    <mat-tab label="General">
      <div class="fields-container w-100">
        @if (playerProService.isAndroidPlayer(displayService.display)) {
          <div class="options-group flex-row mb-2 gap-4 align-start">
            <div>
              <mat-icon aria-hidden="true" svgIcon="alert-triangle"></mat-icon>
            </div>
            <div class="options w-100 flex-col gap-4">
              <div class="option-item">
                <div class="control flex-row space-between">
                  <label class="mb-0">Override Alerts</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isClassroomAlertsAvailable) {
                    <mat-slide-toggle name="toggleOverrideTakeover" [(ngModel)]="displayService.display.overrideTakeoverAllowed" [disabled]="displayService.showLicenseRequired()" id="overrideTakeoverAllowed" />
                  }
                  @if (!displayService.showLicenseRequired() && !isClassroomAlertsAvailable) {
                    <a href="#" class="madero-link" (click)="promptUpgrade('classroom_alerts')">Plan Upgrade Required</a>
                  }
                </div>
                <div class="info text-muted mt-2">Allow Rise Player to take over the screen with overrides replace type schedules.</div>
              </div>
            </div>
          </div>
        }
        <div class="options-group flex-row mb-2 gap-4 align-start">
          <div>
            <mat-icon aria-hidden="true" svgIcon="award"></mat-icon>
          </div>
          <div class="options w-100 flex-col gap-4">
            <div class="option-item">
              <div class="control flex-row space-between">
                <label for="digitalSignageLicense" class="mb-0">License</label>
                <mat-slide-toggle
                  id="digitalSignageLicense"
                  name="toggleLicense"
                  [(ngModel)]="displayService.display.playerProAuthorized"
                  (change)="displayService.toggleProAuthorized()"
                  [disabled]="!playerLicenseService.isProToggleEnabled(displayService.display)" />
              </div>
              <div class="info text-muted mt-2 flex-row">
                <span>Licenses left:&nbsp;</span>
                <display-license-counts [countOnly]="true" />
              </div>
            </div>
            @if (displayService.display.isFreeForURLs) {
              <div class="option-item flex-row">
                <div class="row-entry">
                  <label class="control-label pull-left mb-0"></label>
                  This is a free display.
                </div>
              </div>
            }
          </div>
        </div>
        <div class="options-group flex-row mb-2 gap-4 align-center"
          [shown]="shouldShowRotationOption">
          <div>
            <mat-icon aria-hidden="true" svgIcon="phone-rotate"></mat-icon>
          </div>
          <div class="options w-100 flex-col gap-4">
            <div class="option-item">
              <div class="control flex-row space-between gap-4">
                <label class="mb-0" for="orientation">Rotation</label>
                <select #orientation name="orientation" class="form-control" [(ngModel)]="displayService.display.orientation">
                  <option [ngValue]="0">Landscape</option>
                  <option [ngValue]="90">Portrait</option>
                  <option [ngValue]="180">Landscape (flipped)</option>
                  <option [ngValue]="270">Portrait (flipped)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="options-group flex-row mb-2 gap-4 align-start">
          <div>
            <mat-icon aria-hidden="true" svgIcon="map-pin"></mat-icon>
          </div>
          <div class="options w-100 flex-col gap-4">
            <div class="option-item">
              <div class="control">
                <label class="mb-0">Location</label>
              </div>
            </div>
            <div class="option-item">
              <div class="control flex-row space-between">
                <label id="useCompanyAddressLabel" class="mb-0">Use Company Address</label>
                <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                @if (!displayService.showLicenseRequired()) {
                  <mat-slide-toggle
                    aria-labelledby="useCompanyAddressLabel"
                    name="toggleUseCompanyAddress"
                    [(ngModel)]="displayService.display.useCompanyAddress"
                    [disabled]="displayService.showLicenseRequired()"
                    id="useCompanyAddress" />
                }
              </div>
              <div class="info text-muted mt-2">This will be used for address specific templates like weather and news.</div>
              <div class="mt-2" [shown]="!displayService.display.useCompanyAddress">
                <display-address [(display)]="displayService.display"></display-address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Manage">
      <div class="field-container w-100">
        @if (playerProService.isDisplayControlCompatiblePlayer(displayService.display)) {
          <div class="options-group flex-row mb-2 gap-4 align-start">
            <div>
              <mat-icon aria-hidden="true" svgIcon="alarm-clock"></mat-icon>
            </div>
            <div class="options w-100 flex-col gap-3">
              <div class="option-item">
                <div class="control flex-row space-between">
                  <label id="displayControlLabel" for="toggleDisplayControl" class="mb-0">Display Control:</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                    <mat-slide-toggle
                      aria-labelledby="displayControlLabel"
                      name="toggleDisplayControl"
                      [(ngModel)]="displayService.display.isDisplayControlEnabled"
                      (change)="toggleDisplayControl()"
                      [disabled]="displayService.showLicenseRequired() || !userStateService.hasRole('da')"
                      id="toggleDisplayControl"/>
                  }
                  @if (!displayService.showLicenseRequired() && !isDisplayControlAvailable) {
                    <a href="#" class="madero-link" (click)="promptUpgrade('display_control')">Plan Upgrade Required</a>
                  }
                </div>
                <div class="info text-muted mt-2" [shown]="!displayService.showLicenseRequired()">
                  Turn screen ON/OFF based on schedule.
                </div>
              </div>
              @if (playerProService.isGiadaDN74Product(this.displayService.display) && displayService.display.isDisplayControlEnabled) {
                <div class="option-item">
                  <div class="control flex-row space-between">
                    <label id="modes-radio-group-label" class="mb-0">Modes</label>
                    <div class="flex-row row-wrap gap-4 align-center">
                      <mat-radio-group
                        aria-labelledby="modes-radio-group-label"
                        [(ngModel)]="displayControlType"
                        name="modes-radio-group"
                        (ngModelChange)="detectChanges()">
                        <mat-radio-button id="hdmi-cec" name="hdmiCec" [value]="'hdmi-cec'">HDMI-CEC</mat-radio-button>
                        <mat-radio-button id="rs-232" name="rs232" [value]="'rs-232'">RS-232</mat-radio-button>
                      </mat-radio-group>
                      @if (displayControlType === 'rs-232') {
                        <a class="madero-link u_clickable"
                          (click)="configureDisplayControl()"
                          *requireRole="'da'">
                          Configure
                        </a>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (playerProService.isAutostartFlagEnabledPlayer(displayService.display)) {
          <div class="options-group flex-row mb-2 gap-4 align-start">
            <div>
              <mat-icon aria-hidden="true" svgIcon="power"></mat-icon>
            </div>
            <div class="options w-100 flex-col gap-4">
              <div class="option-item">
                <div class="control flex-row space-between">
                  <label class="mb-0" id="autostart-reboot-label">
                    Autostart after reboot
                  </label>
                  <ng-container *ngTemplateOutlet="licenseRequired" />
                  @if (!displayService.showLicenseRequired()) {
                    <mat-slide-toggle
                      aria-labelledby="autostart-reboot-label"
                      name="toggleAutostartOnBoot"
                      [(ngModel)]="displayService.display.autostartOnBoot"
                      [disabled]="displayService.showLicenseRequired()"
                      id="autostartOnBoot" />
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @if (playerProService.isRebootOrRestartDailyCompatiblePlayer(displayService.display)) {
          <div class="options-group flex-row mb-2 gap-4 align-start">
            <div>
              <mat-icon aria-hidden="true" svgIcon="refresh"></mat-icon>
            </div>
            <div class="options w-100 flex-col gap-4">
              <div class="option-item">
                <div class="control flex-row space-between">
                  <label class="mb-0">
                    {{playerProService.isRebootCompatiblePlayer(displayService.display)
                  ? "Reboot" : "Restart"}} daily</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired()) {
                    <mat-slide-toggle name="toggleRestart" [(ngModel)]="displayService.display.restartEnabled" [disabled]="displayService.showLicenseRequired()" id="restartEnabled" />
                  }
                </div>
              </div>
              @if (displayService.display.restartEnabled) {
                <div class="option-item">
                  <div class="control flex-row space-between">
                    <label for="restartTime" class="mb-0 text-muted">
                      {{playerProService.isRebootCompatiblePlayer(displayService.display)
                       ? 'Reboot' : 'Restart'}} at
                    </label>
                    <popup-time-picker id="restartTime" name="restartTime" ngDefaultControl [(ngModel)]="displayService.display.restartTime" [shown]="!displayService.showLicenseRequired() && displayService.display.restartEnabled"></popup-time-picker>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        @if (!playerProService.isAvocorDisplay(displayService.display)) {
          <div class="options-group flex-row mb-2 gap-4 align-start">
            <div>
              <mat-icon aria-hidden="true" svgIcon="activity"></mat-icon>
            </div>
            <div class="options w-100 flex-col gap-4">
              <div class="option-item">
                <div class="control flex-row space-between">
                  <label class="mb-0">
                    Monitor Activity
                  </label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                  @if (!displayService.showLicenseRequired() && isMonitoringAvailable) {
                    <mat-slide-toggle
                      name="toggleMonitoring"
                      [(ngModel)]="displayService.display.monitoringEnabled"
                      (change)="trackDisplayMonitoring()"
                      [disabled]="displayService.showLicenseRequired()" />
                  }
                  @if (!displayService.showLicenseRequired() && !isMonitoringAvailable) {
                    <a href="#"
                      class="madero-link"
                      (click)="promptUpgrade('monitoring')"
                      >Plan Upgrade Required
                    </a>
                  }
                </div>
                <div class="info text-muted mt-2">
                  Alert me by email when this display goes offline.
                </div>
              </div>
              <div
                class="option-item"
                [shown]="isMonitoringEnabledAndAvailable"
              >
                <div class="control flex-row">
                  <!-- TODO: update timeline component -->
                  <timeline-basic-textbox
                    [(timelineString)]="displayService.display.monitoringSchedule"
                    [disabled]="!displayService.display.monitoringEnabled" />
                </div>
              </div>
              <div class="option-item"
                [shown]="isMonitoringEnabledAndAvailable"
                >
                <div class="control">
                  <label class="mb-0 bold">Recipients</label>
                  <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
                </div>
                @if (!displayService.showLicenseRequired()) {
                  <div class="info mt-2 text-muted">Separate multiple recipients by a comma.</div>
                  <div class="flex-row mt-3" [ngClass]="{ 'has-error': monitoringEmails.errors?.emails }">
                    <emails-field #monitoringEmails="ngModel" name="monitoringEmails" ngDefaultControl [(ngModel)]="displayService.display.monitoringEmails"></emails-field>
                  </div>
                  <div>
                    <span class="help-block text-danger" [shown]="monitoringEmails.errors?.emails">
                      A valid email address is required.
                    </span>
                    <span class="help-block text-danger" [shown]="monitoringEmails.errors?.duplicateEmails">
                      The email already exists.
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </mat-tab>
    @if (isScreenSharingVisible) {
      <mat-tab>
        <ng-template mat-tab-label aria-label="Screen Sharing Settings">
          <span>Screen Sharing</span>
          @if (!isScreenSharingAvailable) {
            <streamline-icon name="circleArrowUp" class="rise-blue u_lh-1 ml-3" width="16" height="16"></streamline-icon>
          }
        </ng-template>
        @if (isScreenSharingAvailable) {
          <div class="fields-container options-group w-100">
            <edit-screen-sharing [model]="displayService.display" [licenseRequired]="licenseRequired" [isLicenseRequired]="displayService.showLicenseRequired()" />
          </div>
        }
      </mat-tab>
    }
    <mat-tab label="Device Info">
      <div class="field-container w-100">
        <div class="options-group">
          <div class="flex-row space-between mb-4" [shown]="displayService.display.playerLocalIpAddress">
            <label class="mb-0">IP Address</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span>
                {{displayService.display.playerLocalIpAddress}}
              </span>
            }
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.macAddress">
            <label class="mb-0">MAC Address</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span>
                {{displayService.display.macAddress}}
              </span>
            }
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.os">
            <label class="mb-0">Operating System</label>
            <span>
              {{displayService.display.os}}
              {{playerProService.isFireOSPlayer(displayService.display) ? " (Fire OS)" : ""}}
            </span>
          </div>
          <div class="flex-row space-between mb-4">
            <label class="mb-0">Resolution</label>
            <span>
              <span [shown]="displayService.display.playerVersion">{{ getResolution() }}</span>
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.playerName || displayService.display.playerVersion">
            <label class="mb-0 aligner-item--top">Player</label>
            <span>
              <span [shown]="displayService.display.playerName">{{displayService.display.playerName}}</span>
              <span [shown]="displayService.display.playerVersion">&nbsp;{{displayService.display.playerVersion}}</span>
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.viewerVersion && displayService.display.viewerVersion !== displayService.display.playerVersion">
            <label class="mb-0">Viewer</label>
            <span>
              {{displayService.display.viewerVersion}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.browserVersion">
            <label class="mb-0">Browser</label>
            <span>
              {{(displayService.display.browserName ? displayService.display.browserName + " " : "") + (displayService.display.browserVersion ? displayService.display.browserVersion : "")}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.serialNumber">
            <label class="mb-0">Serial Number</label>
            <span>
              {{displayService.display.serialNumber}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.cpu">
            <label class="mb-0">CPU</label>
            <span>
              {{displayService.display.cpu}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.hostName">
            <label class="mb-0">Host Name</label>
            <span>
              {{displayService.display.hostName}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.manufacturer" *requireRole="'sa'">
            <label class="mb-0">Manufacturer</label>
            <span>
              {{displayService.display.manufacturer}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.buildType" *requireRole="'sa'">
            <label class="mb-0">Build Type</label>
            <span>
              {{displayService.display.buildType}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.showProductName()">
            <label class="mb-0">Product Name</label>
            <span>
              {{displayService.display.productName}}
            </span>
          </div>
          <div class="flex-row space-between mb-4" [shown]="displayService.display.deviceManagementId">
            <label class="mb-0">Device Management ID</label>
            <span>
              {{displayService.display.deviceManagementId}}
            </span>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="preview-container flex-col space-between">
    @if (isMobile && hideScreenshot) {
      <div class="align-right mb-4">
        <button class="btn btn-default btn-toolbar" (click)="hideScreenshot = false">Show Preview</button>
      </div>
    }
    @else {
      <div class="screenshot-embed">
        <screenshot (licenseRequired)="confirmLicensing()"></screenshot>
        <div>
          <div class="flex-col gap-4 mb-4">
            <activation-notice></activation-notice>
            <div class="flex-col align-start">
              <label class="pull-left mb-3" [shown]="!userStateService.hasRole('cp')">Default Schedule: <span class="font-weight-normal">{{displayService.display.scheduleName || 'None'}}</span></label>
              <div class="flex-row align-end">
                <preview-selector class="flex-grow" [(selectedItem)]="selectedSchedule" (selectedItemChange)="scheduleSelected()" label="Default Schedule:" additionalClass="mr-auto mb-0" placement="bottom left" [shown]="userStateService.hasRole('cp')"></preview-selector>
                <span class="button-toolbar">
                  @if (selectedSchedule) {
                    <a id="viewSchedule" class="madero-link u_inline-block my-3 ml-4" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: selectedSchedule.id, cid: selectedSchedule.companyId }">Edit</a>
                  }
                </span>
              </div>
            </div>
            @if (displayService.hasOverrideSchedule(displayService.display)) {
              <div class="form-group mb-0">
                <label>Override Schedule{{ displayService.display.overrideScheduleIds.length > 1 ? 's' : '' }}:</label>
                @for (override of displayService.display.overrideSchedules; track override) {
                  <div class="flex-row mb-2">
                    @if (override) {
                      <div class="row-entry space-between align-end">
                        <div class="flex-grow u_ellipsis">
                          {{ override.name }}
                        </div>
                        <div class="text-danger mx-4">
                          {{ override.scheduleTypeName }}
                        </div>
                        <div>
                          @if (override.companyId !== companyStateService.getSelectedCompanyId()) {
                            <span class="u_link-disabled">Edit</span>
                          }
                          @else {
                            <a class="madero-link u_clickable" uiSref="apps.schedules.details" [uiParams]="{ scheduleId: override.id, cid: companyStateService.getSelectedCompanyId() }">Edit</a>
                          }
                        </div>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
          <div class="flex-row space-between mb-4">
            <label class="mb-0">Last Connected</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span>
                {{displayService.display.lastActivityDate | date:'d-MMM-yyyy h:mm a'}}
              </span>
            }
          </div>
          <div class="flex-row space-between mb-4" [hidden]="displayService.display.onlineStatus === 'not_activated'">
            <label class="mb-0">Status</label>
            <ng-container *ngTemplateOutlet="licenseRequired"></ng-container>
            @if (!displayService.showLicenseRequired()) {
              <span class="status">
                <span class="text-danger" [shown]="displayService.display.onlineStatus === 'offline'">Offline</span>
                <span class="text-success" [shown]="displayService.display.onlineStatus === 'online'">Online</span>
              </span>
            }
          </div>
          <div class="flex-col mt-5">
            <label>Display ID</label>
            <div class="display-info">
              <div>
                For a dedicated <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/115001817143-What-is-a-Media-Player-get-one-pre-configured-build-your-own-?_ga=2.13275188.2099187533.1636965414-1754393580.1635242387" target="_blank">media player</a> connected to your display:<br>
              </div>
              <div class="flex-row gap-2">
                <div class="highlight-text">{{ displayService.display?.id }}</div>
                <a class="u_clickable madero-link" (click)="copyToClipboard(displayService.display.id)">Copy</a>
              </div>
            </div>
            <div>
              Or, configure a display that can show a <a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/4402342244628-How-do-I-install-Rise-Vision-Player-in-a-web-browser-" target="_blank">full screen webpage</a>:
              <a class="u_clickable madero-link u_nowrap" (click)="showWebPlayer=true" [hidden]="showWebPlayer">Show details.</a>
            </div>
            <span [shown]="showWebPlayer">
              <div class="flex-row space-between mt-2">
                <div class="highlight-text">{{ getWebPlayerUrl() }}</div>
                <a class="u_clickable madero-link u_margin-left mb-auto" (click)="copyToClipboard(getWebPlayerUrl())">Copy</a>
              </div>
            </span>
            <div class="text-muted mt-3">
              Each individual device needs to have a unique Display ID.
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
<div id="errorBox" [shown]="displayService.apiError" class="madero-style alert alert-danger u_margin-md-top" role="alert">
  <strong>{{displayService.errorMessage}}</strong> {{displayService.apiError}}
</div>